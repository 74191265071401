if(!window.consts){
    window.consts = {};
}

window.consts.$win = $(window);
window.consts.$doc = $(document);
window.consts.$body = $('body');
window.consts.$html = $('html');
window.consts.$footer = $('footer');

if(!window.vars){
    window.vars = {};
}

vars.countUpDefault = {
    useEasing: true,
    useGrouping: true,
    decimal: '.',
    separator: '',
};

vars.swiperDefaultConf = {
    autoplay: true,
    loop: true,
    loopAdditionalSlides: 5
};

if(!window.functions){
    window.functions = {};
}

window.functions.showArticleAll = function () {
    let $this = $(this);
    $this.parent().removeClass('article-mask');
};

window.functions.scrollMenu = function () {
    $('.menu-list').each(function(index,item){
        let $el = $(item),
            $active = $el.find('.active'),
            offsetLeft = $active.offset().left;
        if($active.index() > 0) {
            $el.scrollLeft(offsetLeft);
        }
    });
};
function fontRem() {
    var designW = 720;
    var html = document.getElementsByTagName('html')[0];
    var winW = html.offsetWidth;
    html.style.fontSize = (winW / designW) * 100 + 'px';
}
fontRem();
consts.$win.on('resize',()=>{
    fontRem();
});

if(!window.utils){
    window.utils = {};
}

utils.initSwiper = (container,confObj)=>{
    if(!window.Swiper){
        return false;
    }
    let $container = $(container),
        conf = {
            loop: true,
            autoplay: true,
        };
    Object.assign(conf,vars.swiperDefaultConf,confObj);
    if(!$container.length){
        return false;
    }
    return new Swiper(container,conf);
};

utils.encodeHtml = function(str){
    let div = document.createElement('div');
    div.innerHTML = str;
    return div.innerText;
};


(function(root, factory) {
    root = root || {};
    if (typeof define === 'function' && define.amd) {
        define(factory);
    } else if (typeof exports === 'object') {
        module.exports = factory(require, exports, module);
    } else {
        root.CountUp = factory();
    }
}(this, function(require, exports, module) {
    
    /*

        countUp.js
        by @inorganik

    */

// target = id of html element or var of previously selected html element where counting occurs
// startVal = the value you want to begin at
// endVal = the value you want to arrive at
// decimals = number of decimal places, default 0
// duration = duration of animation in seconds, default 2
// options = optional object of options (see below)
    
    var CountUp = function(target, startVal, endVal, decimals, duration, options) {
        
        var self = this;
        self.version = function () { return '1.9.3'; };
        
        // default options
        self.options = {
            useEasing: true, // toggle easing
            useGrouping: true, // 1,000,000 vs 1000000
            separator: ',', // character to use as a separator
            decimal: '.', // character to use as a decimal
            easingFn: easeOutExpo, // optional custom easing function, default is Robert Penner's easeOutExpo
            formattingFn: formatNumber, // optional custom formatting function, default is formatNumber above
            prefix: '', // optional text before the result
            suffix: '', // optional text after the result
            numerals: [] // optionally pass an array of custom numerals for 0-9
        };
        
        // extend default options with passed options object
        if (options && typeof options === 'object') {
            for (var key in self.options) {
                if (options.hasOwnProperty(key) && options[key] !== null) {
                    self.options[key] = options[key];
                }
            }
        }
        
        if (self.options.separator === '') {
            self.options.useGrouping = false;
        }
        else {
            // ensure the separator is a string (formatNumber assumes this)
            self.options.separator = '' + self.options.separator;
        }
        
        // make sure requestAnimationFrame and cancelAnimationFrame are defined
        // polyfill for browsers without native support
        // by Opera engineer Erik M枚ller
        var lastTime = 0;
        var vendors = ['webkit', 'moz', 'ms', 'o'];
        for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
            window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
            window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame'] || window[vendors[x]+'CancelRequestAnimationFrame'];
        }
        if (!window.requestAnimationFrame) {
            window.requestAnimationFrame = function(callback, element) {
                var currTime = new Date().getTime();
                var timeToCall = Math.max(0, 16 - (currTime - lastTime));
                var id = window.setTimeout(function() { callback(currTime + timeToCall); }, timeToCall);
                lastTime = currTime + timeToCall;
                return id;
            };
        }
        if (!window.cancelAnimationFrame) {
            window.cancelAnimationFrame = function(id) {
                clearTimeout(id);
            };
        }
        
        function formatNumber(num) {
            var neg = (num < 0),
                x, x1, x2, x3, i, len;
            num = Math.abs(num).toFixed(self.decimals);
            num += '';
            x = num.split('.');
            x1 = x[0];
            x2 = x.length > 1 ? self.options.decimal + x[1] : '';
            if (self.options.useGrouping) {
                x3 = '';
                for (i = 0, len = x1.length; i < len; ++i) {
                    if (i !== 0 && ((i % 3) === 0)) {
                        x3 = self.options.separator + x3;
                    }
                    x3 = x1[len - i - 1] + x3;
                }
                x1 = x3;
            }
            // optional numeral substitution
            if (self.options.numerals.length) {
                x1 = x1.replace(/[0-9]/g, function(w) {
                    return self.options.numerals[+w];
                })
                x2 = x2.replace(/[0-9]/g, function(w) {
                    return self.options.numerals[+w];
                })
            }
            return (neg ? '-' : '') + self.options.prefix + x1 + x2 + self.options.suffix;
        }
        // Robert Penner's easeOutExpo
        function easeOutExpo(t, b, c, d) {
            return c * (-Math.pow(2, -10 * t / d) + 1) * 1024 / 1023 + b;
        }
        function ensureNumber(n) {
            return (typeof n === 'number' && !isNaN(n));
        }
        
        self.initialize = function() {
            if (self.initialized) return true;
            
            self.error = '';
            self.d = (typeof target === 'string') ? document.getElementById(target) : target;
            if (!self.d) {
                self.error = '[CountUp] target is null or undefined'
                return false;
            }
            self.startVal = Number(startVal);
            self.endVal = Number(endVal);
            // error checks
            if (ensureNumber(self.startVal) && ensureNumber(self.endVal)) {
                self.decimals = Math.max(0, decimals || 0);
                self.dec = Math.pow(10, self.decimals);
                self.duration = Number(duration) * 1000 || 2000;
                self.countDown = (self.startVal > self.endVal);
                self.frameVal = self.startVal;
                self.initialized = true;
                return true;
            }
            else {
                self.error = '[CountUp] startVal ('+startVal+') or endVal ('+endVal+') is not a number';
                return false;
            }
        };
        
        // Print value to target
        self.printValue = function(value) {
            var result = self.options.formattingFn(value);
            
            if (self.d.tagName === 'INPUT') {
                this.d.value = result;
            }
            else if (self.d.tagName === 'text' || self.d.tagName === 'tspan') {
                this.d.textContent = result;
            }
            else {
                this.d.innerHTML = result;
            }
        };
        
        self.count = function(timestamp) {
            
            if (!self.startTime) { self.startTime = timestamp; }
            
            self.timestamp = timestamp;
            var progress = timestamp - self.startTime;
            self.remaining = self.duration - progress;
            
            // to ease or not to ease
            if (self.options.useEasing) {
                if (self.countDown) {
                    self.frameVal = self.startVal - self.options.easingFn(progress, 0, self.startVal - self.endVal, self.duration);
                } else {
                    self.frameVal = self.options.easingFn(progress, self.startVal, self.endVal - self.startVal, self.duration);
                }
            } else {
                if (self.countDown) {
                    self.frameVal = self.startVal - ((self.startVal - self.endVal) * (progress / self.duration));
                } else {
                    self.frameVal = self.startVal + (self.endVal - self.startVal) * (progress / self.duration);
                }
            }
            
            // don't go past endVal since progress can exceed duration in the last frame
            if (self.countDown) {
                self.frameVal = (self.frameVal < self.endVal) ? self.endVal : self.frameVal;
            } else {
                self.frameVal = (self.frameVal > self.endVal) ? self.endVal : self.frameVal;
            }
            
            // decimal
            self.frameVal = Math.round(self.frameVal*self.dec)/self.dec;
            
            // format and print value
            self.printValue(self.frameVal);
            
            // whether to continue
            if (progress < self.duration) {
                self.rAF = requestAnimationFrame(self.count);
            } else {
                if (self.callback) self.callback();
            }
        };
        // start your animation
        self.start = function(callback) {
            if (!self.initialize()) return;
            self.callback = callback;
            self.rAF = requestAnimationFrame(self.count);
        };
        // toggles pause/resume animation
        self.pauseResume = function() {
            if (!self.paused) {
                self.paused = true;
                cancelAnimationFrame(self.rAF);
            } else {
                self.paused = false;
                delete self.startTime;
                self.duration = self.remaining;
                self.startVal = self.frameVal;
                requestAnimationFrame(self.count);
            }
        };
        // reset to startVal so animation can be run again
        self.reset = function() {
            self.paused = false;
            delete self.startTime;
            self.initialized = false;
            if (self.initialize()) {
                cancelAnimationFrame(self.rAF);
                self.printValue(self.startVal);
            }
        };
        // pass a new endVal and start animation
        self.update = function (newEndVal) {
            if (!self.initialize()) return;
            newEndVal = Number(newEndVal);
            if (!ensureNumber(newEndVal)) {
                self.error = '[CountUp] update() - new endVal is not a number: '+newEndVal;
                return;
            }
            self.error = '';
            if (newEndVal === self.frameVal) return;
            cancelAnimationFrame(self.rAF);
            self.paused = false;
            delete self.startTime;
            self.startVal = self.frameVal;
            self.endVal = newEndVal;
            self.countDown = (self.startVal > self.endVal);
            self.rAF = requestAnimationFrame(self.count);
        };
        
        // format startVal on initialization
        if (self.initialize()) self.printValue(self.startVal);
    };
    if(!window.CountUp){
        window.CountUp = CountUp;
    }
    
    return CountUp;
    
}));
//判断是否为偶数
function isEven(number) {
	return typeof number === 'number' && number % 2 === 0;
}

//获取最近的一个偶数(往上找)
function getNearEvenNumber(number) {
	number = parseInt(number);
	if(typeof number !== 'number') return false;
	if(!isEven(number)){
		number++;
	}
	return number;
}

/*
	* 模态框 start
 */
function showModelInit() {
	$('.show-model').on('click',function () {
		var id = $(this).data('target');
		showModel(id);
	})
}

function closeModelInit() {
	$('.hide-model').on('click',function () {
		var id = $(this).parents('.model').attr('id');
		closeModel(id);
	})
}

function showModel(id){
	var $id = $('#' + id);
	$id.slideDown().addClass('active');
	$('.model-mask').fadeIn(function () {
		var height = $id.outerHeight();
		height = getNearEvenNumber(height);
		$id.outerHeight(height);
	});
}

function closeModel(id){
	var $id = $('#' + id);
	$id.slideUp();
	$('.model-mask').fadeOut(function () {
		$id.removeClass('active');
	});
}

$(function () {
	//初始化
	showModelInit();
	closeModelInit();
});
/*
	* 模态框 end
 */


function initLayDate(opt){
    let defObj = {
        format: 'yyyy-MM-dd', //可任意组合
        trigger: 'click', //采用click弹出
        theme: '#49bad9', //主题颜色
    };
    let confObj = Object.assign(opt,defObj);
    return laydate.render(confObj);
}

function compareTime(elStartTime,elEndTime,{cbReady = ()=>{},cbDone = ()=>{}}){
    let $elStartTime = $(elStartTime),
        $elEndTime = $(elEndTime),
        date = new Date(),
        today = {
            year: date.getFullYear(),
            month: date.getMonth(),
            date: date.getDate(),
            hours: date.getHours(),
            minutes: date.getMinutes(),
            seconds: date.getSeconds(),
        },
        minDay = '1900-1-1';

    screenKeyboard(elStartTime);
    screenKeyboard(elEndTime);
    var sdInstance = initLayDate({
        elem: elStartTime,
        max: 0,
        btns: ['clear', 'confirm'],
        ready: function(){
            cbReady($elStartTime,$elStartTime.val(),$elEndTime.val(),sdInstance);
        },
        done: function(value,date){
            if(value){
                date = Object.assign({},date);
                date.month--;
                edInstance.config.min = date;
            }else{
                if($elEndTime.val()){
                    sdInstance.config.max = edInstance.config.dateTime;
                }else{
                    sdInstance.config.max = today;
                }
            }
            setTimeout(()=>{
                if(!$elEndTime.val() && value){
                    $elEndTime.trigger('click');
                }
            });
            cbDone($elStartTime,$elStartTime.val(),$elEndTime.val(),sdInstance);
        }
    });
    var edInstance = initLayDate({
        elem: elEndTime,
        max: 0,
        btns: ['clear', 'confirm'],
        ready: function(){
            cbReady($elEndTime,$elStartTime.val(),$elEndTime.val(),edInstance);
        },
        done: function (value,date) {
            if(value){
                date = Object.assign({},date);
                date.month--;
                sdInstance.config.max = date;
            }else{
                if($elStartTime.val()){
                    edInstance.config.min = sdInstance.config.dateTime;
                }else{
                    edInstance.config.min = minDay;
                }
            }
            cbDone($elEndTime,$elStartTime.val(),$elEndTime.val(),edInstance);
        }
    });
}

function screenKeyboard(el = ''){
    $('body').on("focus",el,function(){
        document.activeElement.blur();//屏蔽默认键盘弹出；
    });
}
function checkInView(el){
    var $el = $(el);
    return new Promise((resolve,reject)=>{
        $(window).on('scroll',function () {
            var wHeight = $(window).height();
            var mTop = $el.get(0).getBoundingClientRect().top;
            if(mTop <= wHeight){
                resolve();
            }
        }).trigger('scroll');
    });
}

function getRandomNumber(){
    return Math.ceil((new Date().getTime()) * Math.random() * Math.random());
}

$(function () {
    let rootFontSize = parseFloat($('html').css('font-size'));

    {
        //swiper init
        utils.initSwiper('.swiper-index-slide',{
            pagination: {
                el: '.swiper-index-slide .swiper-pagination',
                clickable: true,
            },
        });

        let donateListSwiperLength = 6;
        if($('.donate-list-swiper .swiper-slide').length >= donateListSwiperLength){
            $('.donate-list-swiper').css({
                'height': Math.ceil(rootFontSize * donateListSwiperLength * 0.72),
            });
            $('.donate-list-swiper .donate-info-item').css({
                'height': rootFontSize * .72,
            });
            utils.initSwiper('.donate-list-swiper',{
                direction : 'vertical',
                slidesPerView : donateListSwiperLength,
                roundLengths : true,
                autoplay: {
                  delay: 1000,
                },
            });
        }else{
            $('.donate-list-swiper').css({
                height: 'auto'
            });
        }
    }

    $('.article-mask').each(function(){
        let $el = $(this);
        if($el.height() > rootFontSize * 4.2){
            $el.addClass('active');
        }
    });

    $('.show-more').on('click',function () {
        window.functions.showArticleAll.call(this);
    });

    $('.share-friends').click(function(e) {
        e.preventDefault();
        $('.mask-weixin').fadeIn();
    });
    $('.mask-weixin').on('click',function (){
        $(this).fadeOut();
    });
    window.functions.scrollMenu();
    
    $('.count').each(function () {
        var $this = $(this),
            id = $this.attr('id'),
            number = $this.data('number'),
            decimalsArr = String(number).split('.'),//获取小数点后的数字
            decimals = decimalsArr.length === 2 && decimalsArr[decimalsArr.length - 1].length,
            unitText = '';
        
        //处理数字
        if(!Number(number)){
            unitText = number[number.length - 1];
            number = parseFloat(number);
            decimals && decimals--;
            $this.after(`<span class="unit-text">${unitText}</span>`);
        }
    
        //fixed width
        $this.text(number)
            .width($this.width());
        
        if(!id){
            id = 'count-' + getRandomNumber();
            $this.attr('id',id);
        }
        
        var countUp = new CountUp(id, 0, number, decimals, 2.5,  {
            useEasing: true,
            useGrouping: true,
            decimal: '.',
            separator: '',
            decimalPlaces: 2
        });
        
        if (countUp.error) {
            return false;
        }
        
        checkInView($this).then(()=>{
            countUp.start();
        })
    });
    
    $('body').on('click', '.magnific-popup-iframe', function () {
        var video = $(this).data('src');
        $.magnificPopup.open({
            items: {
                src: `<div>
						<video  autoplay="autoplay" src="${video}" controls="controls">
	                    your browser does not support the video tag
	                    </video>
                    </div>`
            },
            type: 'inline',
            callbacks: {
                beforeOpen: function () {
                    var scrollTop = window.magnificScrollTop = $(document).scrollTop();
                    $('body').css({
                        position: 'fixed',
                        left: 0,
                        top: 0,
                        width: '100%',
                        marginTop: -scrollTop
                    });
                    setTimeout(function () {
                        $('.mfp-wrap').css({
                            top: scrollTop,
                        }).find('video').get(0).play();
                    });
                },
                afterClose: function () {
                    $('body').css({
                        position: 'static',
                        left: 'unset',
                        top: 'unset',
                        width: 'auto',
                        marginTop: 0
                    });
                    setTimeout(function () {
                        window.scrollTo(0, magnificScrollTop);
                    });
                }
            }
        });
    });
    
});


